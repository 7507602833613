<template>
  <div class="events" v-if="currentRoute">
    <stage :stageLabel="$i18n.locale === 'de' ? 'Veranstaltungen' : 'Events'" :big="true" :title="currentRoute.title.rendered"/>
    <introduction
        v-if="currentRoute.acf.description"
        fontWeight="bold"
        :subline="currentRoute.title.rendered"
        :maintext="currentRoute.acf.description"
    />    
    <div v-if="pagedEvents.length > 0" ref="eventslist" class="events__content">
        <projects :items="pagedEvents"/>
        <pagination @change-current="setCurrentPage" :currentPage="currentPage" :totalPages="Number(getEventsListProps.totalPages)"/>
    </div>
    <div v-else ref="eventslist" class="events__content events__content--empty">
        <h2>
          {{$i18n.locale === 'de' ? 'Aktuell sind keine Veranstaltungen verfügbar.' : 'No events are currently available.'}}
        </h2>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import Projects from '@/components/jobs-blocks.vue'
import {mapActions, mapGetters} from 'vuex'
import Pagination from '../components/pagination.vue'

export default {
  name: 'Veranstaltungen',
  components: {
    Stage,
    Introduction,
    Projects,
    Pagination
  },
  data() {
    return {
        currentPage: 1,
    }
  },
  computed: {
      ...mapGetters([
          'getEvents',
          'getEventsListProps',
          'getFetched',
      ]),
      currentRoute(){
          const getFetched =  this.getFetched[this.$route.name];
          return getFetched;
      },
      pagedEvents() {
        return this.getEvents.slice((this.currentPage - 1) * 10, this.currentPage * 10)
      }
  },
  created(){
      if(!this.currentRoute) {
          this.fetchCurrentPage(this.$route.name);
      }
      if(this.getEvents.length === 0) {
          this.fetchEvents(this.currentPage)
      }
  },
  watch: {
      currentPage( cur, old) {
          if(Number(this.getEventsListProps.totalItems) > old * 10 && this.getEvents.length !== Number(this.getEventsListProps.totalItems)) {
              this.fetchEvents(cur)
          }
          this.scrollTo(this.$refs.eventslist);
      }
  },
  methods: {
      ...mapActions([
          'fetchEvents',
          'fetchCurrentPage',
      ]),
      setCurrentPage(val) {
          if(val <= this.getEventsListProps.totalPages && val > 0) {
              this.currentPage = val
          }
      },
      scrollTo(ref) {
      window.scrollTo({
        top: ref.offsetTop - 150,
        left: 0,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.events {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: 65px auto 0;
    padding:  0 .8rem;
    &--empty {
      text-align: center;
      margin-bottom: 86px;
    }
  }
}
</style>
